import React, { useEffect, useState } from "react";
// next utils
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { NextSeo } from "next-seo";
// store utils
import { useStore } from "outstated";
import { AppStore } from "pages/_app";
// utils
import getFrontPageHomeListings from "lib/getFrontPageHomeListings";
import ReactTextTransition, { presets } from "react-text-transition";
// local components
import Referrals from "components/Referrals";
import ListingCard from "components/listings/ListingCard";
import dscrLoanStatePages from "components/blog/dscrLoanStatePages";
// mui components
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

// import Testimonials from "components/Testimonials";
const Testimonials = dynamic(() => import("components/Testimonials/index"));
export async function getStaticProps() {
  const listingsData = await getFrontPageHomeListings();

  const targetUrlCapital = `${process.env.CMS_URL}/capital`;
  const resCapital = await fetch(targetUrlCapital);
  const CapitalData = await resCapital.json();

  return {
    props: {
      listingsData,
      CapitalData,
    },
    revalidate: 60 * 60, // if new info or update, revalidate once an hour
  };
}

function Home({ listingsData, CapitalData }) {
  const {
    isMobile,
    setIsMobile,
    isSafari,
    setSelectedPlan,
    setSurveyChainType,
    user,
    setUser,
    homeListings,
    scheduledToursData,
    buyerTours,
    userProfile,
  } = useStore(AppStore);

  const theme = useTheme();
  const router = useRouter();

  const [showModal, setShowModal] = useState(false);
  const [loadedNoSSR, setLoadedNoSSR] = useState(false);
  const [headlineWordsIndex, setHeadlineWordsIndex] = useState(0);
  const headlineWords = [
    "DSCR Purchase",
    "DSCR Refinance",
    // "Fix and Flip",
    // "Low Balance"
    // "Slow Flip",
  ];

  useEffect(() => {
    if (typeof window !== "undefined") {
      // only show sign up if havent seen it in 5 days
      const lastTimeShowedModal = localStorage.getItem("lastTimeShowedModal");
      const nowDate = new Date();
      const fiveDaysAgo = new Date(Date.now() - 1000 * (60 * 60 * 24 * 5));

      if (new Date(lastTimeShowedModal) < new Date(fiveDaysAgo)) {
        // time to show again
        setTimeout(function () {
          setShowModal(true);
        }, 29999);

        localStorage.setItem("lastTimeShowedModal", nowDate);
      }
    }
    setLoadedNoSSR(true);

    // if (process.env.deployment === "production") {
    // so FB and other 3rd party code plugins dont log errors
    // and we don't get dinged for it by lighthouse
    // console.log = function () {};
    // }
    let interval = setInterval((headlineWordsIndex) => {
      setHeadlineWordsIndex((headlineWordsIndex) => {
        if (headlineWordsIndex === headlineWords.length - 1) {
          setHeadlineWordsIndex(0);
        } else {
          setHeadlineWordsIndex(headlineWordsIndex + 1);
        }
      });
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", overflowX: "hidden" }}
    >
      <NextSeo
        title={
          "OfferMarket | DSCR loans, fix and flip loans, landlord insurance, off market listings"
        }
        description={
          "Real estate investing platform and private lender. Access exclusive off market deals, DSCR loans, fix and flip loans, landlord insurance."
        }
        image={`${process.env.CMS_URL}/uploads/mi_root_0d3e5dc416.jpeg`}
        openGraph={{
          title:
            "Investment Property Marketplace and Private Lender - OfferMarket",
          description:
            "Investment property marketplace and private lending platform. Access exclusive off market listings, rental loans and bridge loans.",
          images: [
            {
              url: `${process.env.CMS_URL}/uploads/mi_root_0d3e5dc416.jpeg`,
              alt: "Real Estate Marketplace, OfferMarket.us",
            },
          ],
          site_name: "OfferMarket.us",
        }}
        twitter={{
          handle: "@OfferMarketUS",
          site: "@OfferMarketUS",
          cardType: "summary_large_image",
        }}
      />

      <Box
        sx={{
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.dark,
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            margin: theme.spacing(15, "auto", 5, "auto"),
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            fontWeight: 500,
            maxWidth: "60%",
            [theme.breakpoints.down("md")]: {
              margin: theme.spacing(4, 4, 0, 4),
              maxWidth: "100%",
            },
          }}
        >
          <Typography
            component="h1"
            sx={{
              height: "auto",
              display: "inline",
              fontFamily: "Montserrat",
              lineHeight: "1em",
              fontSize: "1em",
              margin: "auto",
              fontSize: theme.spacing(7),
              [theme.breakpoints.down("md")]: {
                fontSize: theme.spacing(5),
              },
            }}
          >
            {/* Fix and Flip loans, DSCR loans, Insurance and Off Market Deals */}
            Your home for{" "}
            <ReactTextTransition
              springConfig={presets.gentle}
              style={{ margin: "0 4px" }}
              inline
            >
              {headlineWords[headlineWordsIndex]}
            </ReactTextTransition>{" "}
            loans
          </Typography>
          <Typography
            component="h2"
            sx={{
              display: "flex",
              margin: theme.spacing(0, "auto", 0, "auto"),
              padding: theme.spacing(1),
              fontSize: theme.spacing(3),
              [theme.breakpoints.down("md")]: {
                margin: theme.spacing(2, "auto", 0, "auto"),
                fontSize: theme.spacing(2),
              },
            }}
          >
            Lowest rates, highest leverage, lowest fees
          </Typography>
          <Box sx={{ margin: theme.spacing(3, "auto", 0, "auto") }}>
            <Button
              color="success"
              variant={"contained"}
              sx={{
                fontWeight: 600,
                display: "flex",
                textDecoration: "none !important",
                color: "white !important",
                margin: theme.spacing("70%", 0, 0, 0),
                "&:hover": {
                  color: `${theme.palette.primary.contrastColor} !important`,
                  backgroundColor: `${theme.palette.success.dark} !important`,
                },
                [theme.breakpoints.down("md")]: {
                  width: "auto",
                },
              }}
              href={"/loans"}
            >
              Instant quote{" "}
              <Box
                sx={{
                  padding: theme.spacing(0, 0, 0, 1),
                  fontSize: theme.spacing(3.5),
                }}
              >
                {" "}
                🚀
              </Box>
            </Button>
          </Box>
          <Typography
            component="h2"
            sx={{
              display: "flex",
              margin: theme.spacing(0, "auto", 0, "auto"),
              padding: theme.spacing(1),
              fontSize: theme.spacing(1.5),
              [theme.breakpoints.down("md")]: {
                margin: theme.spacing(2, "auto", 0, "auto"),
                fontSize: theme.spacing(2),
              },
            }}
          >
            *Takes 1 minute, no credit pull
          </Typography>
        </Box>
      </Box>

      <Box
        style={{
          margin: theme.spacing(3, 0, 0, 0),
          width: "100%",
          padding: "0 10%",
          minWidth: 0,
          maxWidth: "none",
        }}
      >
        <Box>
          <Typography
            component="h2"
            sx={{
              fontFamily: "Montserrat",
              // lineHeight: 1,
              fontSize: theme.spacing(6),
              margin: "auto",
              fontWeight: 600,
              [theme.breakpoints.down("md")]: {
                fontSize: theme.spacing(5),
              },
            }}
          >
            Off market property listings
          </Typography>

          <Box
            sx={{
              margin: "0 auto 0% auto",
              fontSize: "28px",
              [theme.breakpoints.down("md")]: {
                margin: "3% 0",
                fontSize: "24px",
              },
            }}
          >
            Talk directly to the seller
          </Box>
          <Box
            sx={{
              margin: 0,
              display: "flex",
              [theme.breakpoints.down("md")]: { flexDirection: "column" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-evenly",
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                },
                margin: theme.spacing(3, 0, 0, 0),
              }}
            >
              {listingsData.map((listing, i) => {
                return (
                  <Box
                    sx={{
                      margin: theme.spacing(1),
                      width: "27%",
                      [theme.breakpoints.down("md")]: {
                        width: "auto",
                      },
                    }}
                  >
                    <ListingCard listing={listing} />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              margin: "0% auto 2% auto",
            }}
          >
            <Button
              sx={{
                margin: theme.spacing(5),
                marginLeft: "auto",
                [theme.breakpoints.down("md")]: {
                  margin: theme.spacing(1),
                  width: "100%",
                },
              }}
              onClick={() => {
                router.push("/listings");
              }}
              variant="contained"
              color="success"
              size="large"
              aria-label={"Show more listings for sale"}
            >
              Show more
            </Button>
          </Box>
        </Box>

        <Box>
          <Typography
            component="h2"
            sx={{
              fontFamily: "Montserrat",
              lineHeight: 1,
              fontSize: theme.spacing(6),
              margin: theme.spacing(6, 0, 0, 0),
              fontWeight: 600,
              [theme.breakpoints.down("md")]: {
                fontSize: theme.spacing(5),
              },
            }}
          >
            Reviews
          </Typography>
          <Box
            sx={{
              margin: "0 auto 0% auto",
              fontSize: "28px",
              [theme.breakpoints.down("md")]: {
                margin: "3% 0",
                fontSize: "24px",
              },
            }}
          >
            Check out our{" "}
            <a
              href={`https://www.google.com/search?q=offermarket&source=hp&ei=guDJYtiaDsye5NoPy8q7-AE&iflsig=AJiK0e8AAAAAYsnuks0nomJTGzh53Nyl4buJicDeAldy&ved=0ahUKEwjY4-yvzuz4AhVMD1kFHUvlDh8Q4dUDCAk&uact=5&oq=offermarket&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEIAEMgUIABCABDIECAAQCjIECAAQCjIECAAQCjIECAAQCjIECAAQCjIECAAQCjIECAAQCjIGCAAQHhAKOgsILhCABBCxAxCDAToRCC4QgAQQsQMQgwEQxwEQowI6CwgAEIAEELEDEIMBOggIABCABBCxAzoLCC4QgAQQsQMQ1AI6CAguEIAEELEDOg4ILhCABBCxAxDHARCjAjoICAAQgAQQyQM6BQgAEJIDOg4ILhCABBDHARDRAxDUAjoECAAQAzoICAAQsQMQgwE6BQguEIAEOgcIABCABBAKUABYywpgygtoAHAAeACAAVuIAfMFkgECMTGYAQCgAQE&sclient=gws-wiz#lrd=0x89b7dfa5dd806c9b:0x9da546cdfa9772d1,1,,,`}
            >
              google reviews
            </a>{" "}
            and{" "}
            <a href={`https://www.facebook.com/OfferMarketUS/reviews/`}>
              facebook reviews
            </a>
          </Box>
          {/* {loadedNoSSR && <Testimonials />} */}
        </Box>

        <Box sx={{ margin: theme.spacing(6, 0) }}>
          <Typography
            component="h2"
            sx={{
              fontFamily: "Montserrat",
              lineHeight: 1,
              fontSize: theme.spacing(6),
              fontWeight: 600,
              [theme.breakpoints.down("md")]: {
                fontSize: theme.spacing(5),
              },
            }}
          >
            DSCR loan
          </Typography>
          <Box
            sx={{
              margin: "0 auto 0% auto",
              fontSize: "28px",
              [theme.breakpoints.down("md")]: {
                margin: "3% 0",
                fontSize: "24px",
              },
            }}
          >
            Everything there is to know about our{" "}
            <Link
              sx={{ color: theme.palette.highContrastLink.main }}
              rel="noopener"
              target="_blank"
              href={"https://www.offermarket.us/dscr-loan"}
            >
              DSCR loan
            </Link>{" "}
            product. Search by state below:
          </Box>
          <Box
            sx={{
              margin: theme.spacing(5, "auto", 0, "auto"),
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 2,
              maxWidth: "700px",
              [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "1fr",
              },
            }}
          >
            {(() => {
              const sortedPages = dscrLoanStatePages.sort();
              const midpoint = Math.ceil(sortedPages.length / 2);
              const column1 = sortedPages.slice(0, midpoint);
              const column2 = sortedPages.slice(midpoint);

              const renderColumn = (slugs) => {
                return slugs.map((slug) => {
                  const baseUrl =
                    process.env.deployment === "dev"
                      ? "http://localhost:3000"
                      : "https://www.offermarket.us";

                  let stateName = slug.charAt(0).toUpperCase() + slug.slice(1);

                  if (stateName === "Washington-dc") {
                    stateName = "Washington DC";
                  } else if (stateName.includes("-")) {
                    const parts = stateName.split("-");
                    stateName = parts
                      .map(
                        (part) => part.charAt(0).toUpperCase() + part.slice(1)
                      )
                      .join(" ");
                  }

                  return (
                    <Paper
                      key={slug}
                      elevation={0}
                      component="div"
                      sx={{
                        transition: "all 0.2s ease",
                        "&:hover": {
                          transform: "translateY(-2px)",
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      <Link
                        href={`${baseUrl}/dscr-loan/${slug}`}
                        tabIndex={0}
                        component="a"
                        sx={{
                          display: "block",
                          width: "100%",
                          padding: theme.spacing(1, 0.25),
                          borderRadius: 1,
                          backgroundColor: theme.palette.background.paper,
                          border: `1px solid ${theme.palette.divider}`,
                          textDecoration: "none",
                          "&:hover": {
                            backgroundColor: theme.palette.action.hover,
                            borderColor: theme.palette.primary.main,
                            color: theme.palette.primary.main,
                          },
                          "&:focus": {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: "2px",
                          },
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "inherit",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                        >
                          DSCR loan {stateName}
                        </Typography>
                      </Link>
                    </Paper>
                  );
                });
              };

              return (
                <>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
                  >
                    {renderColumn(column1)}
                  </Box>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
                  >
                    {renderColumn(column2)}
                  </Box>
                </>
              );
            })()}
          </Box>
        </Box>
        {/* Removing referrals from this page */}
        {/* <Box
          sx={{
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
            width: "50%",
            margin: theme.spacing(6, "auto"),
          }}
        >
          <Referrals user={user} userProfile={userProfile} />
        </Box> */}
      </Box>
    </div>
  );
}

export default Home;
